body,
html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  min-height: 100%;
}

.gmnoprint,
.gm-style-cc {
  visibility: hidden !important;
}

.gm-style > div:nth-child(3) {
  visibility: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  flex: 1;
  flex-grow: 1;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.leaflet-control-attribution {
  display: none;
}
